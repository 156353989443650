import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import './App.css';

const ReEscalation = () => {
    const [searchParams] = useSearchParams();
    const [formData, setFormData] = useState({
        product: searchParams.get('product') || '',
        title: searchParams.get('title') || '',
        ticketId: searchParams.get('ticketId') || '',
        organizationName: searchParams.get('organizationName') || ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        message: '',
        isError: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await axios.post(
                'https://svfusion1.eastus2.cloudapp.azure.com/api/escalateTicketFromZendesk',
                formData,
                { headers: { 'Content-Type': 'application/json' } }
            );
            
            setModalContent({
                title: 'Success',
                message: 'The ticket has been successfully re-escalated to ADO.',
                isError: false
            });
            setShowModal(true);
        } catch (error) {
            setModalContent({
                title: 'Error',
                message: `Failed to re-escalate ticket: ${error.response?.data?.message || error.message}`,
                isError: true
            });
            setShowModal(true);
            console.error("Re-escalation Error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                <h1 className="text-2xl font-bold mb-6 text-center">Re-escalate Ticket to ADO</h1>
                
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="product">
                            Product
                        </label>
                        <select
                            id="product"
                            name="product"
                            value={formData.product}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        >
                            <option value="">Select a product</option>
                            <option value="Response">Response</option>
                            <option value="Cases">Cases</option>
                            <option value="Field Ops">Field Ops</option>
                            <option value="Suite">Suite</option>
                            <option value="Integration">Integration</option>
                            <option value="Enterprise Response">Enterprise Response</option>
                            <option value="Enterprise Field Ops">Enterprise Field Ops</option>
                            <option value="Enterprise 24">Enterprise 24</option>
                            <option value="Cases 24">Cases 24</option>
                            <option value="Field Ops 24">Cases 24</option>
                        </select>
                    </div>
                    
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                            Title
                        </label>
                        <input
                            id="title"
                            name="title"
                            type="text"
                            value={formData.title}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Ticket title"
                            required
                        />
                    </div>
                    
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ticketId">
                            Ticket ID
                        </label>
                        <input
                            id="ticketId"
                            name="ticketId"
                            type="text"
                            value={formData.ticketId}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Zendesk ticket ID"
                            required
                        />
                    </div>
                    
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="organizationName">
                            Organization Name
                        </label>
                        <input
                            id="organizationName"
                            name="organizationName"
                            type="text"
                            value={formData.organizationName}
                            onChange={handleChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Customer organization name"
                            required
                        />
                    </div>
                    
                    <div className="flex items-center justify-center">
                        <button
                            type="submit"
                            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Processing...' : 'Re-escalate to ADO'}
                        </button>
                    </div>
                </form>
                
                {showModal && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                        <div className="bg-white p-5 rounded-lg shadow-xl max-w-md mx-auto">
                            <h2 className={`font-bold text-lg mb-4 ${modalContent.isError ? 'text-red-600' : 'text-green-600'}`}>
                                {modalContent.title}
                            </h2>
                            <p className="mb-4">{modalContent.message}</p>
                            <div className="flex justify-center">
                                <button 
                                    onClick={closeModal} 
                                    className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReEscalation;